<template>
  <div class="quick-wrap" v-show="show" @touchmove.prevent>
    <div class="quick-order">
      <div class="icon-close" @click="close">
        <span class="iconfont icon1">&#xe614;</span>
        <span class="iconfont icon2">&#xe606;</span>
      </div>
      <div class="title">{{L.effectiveAccount}}</div>
      <!-- DS的客户列表 -->
      <div class="table-wrap" v-if="list.length>0">
        <el-table
          border
          size="mini"
          :max-height="(list.length>0 && ledList.length>0)?200:400"
          v-loading="allLoading"
          style="width: 100%"
          :data="list"
          @row-click="e=>rowClick(e,1)"
          :row-class-name="e=>rowClassName(e,1)"
        >
          <el-table-column
            align="center"
            prop="customerAccountNo"
            width="100"
            label="Select">
            <template slot-scope="scope">
              <span class="iconfont icon-check icon-active" v-show="tableSelect==scope.row.customerAccountNo">&#xe730;</span>
              <span class="iconfont icon-check" v-show="tableSelect!=scope.row.customerAccountNo">&#xe72f;</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="customerName"
            label="CI Customer Name">
          </el-table-column>
          <el-table-column
            align="center"
            prop="customerAccountNo"
            label="CI Customer Account No.">
          </el-table-column>
        </el-table>
      </div>
      <!-- led客户列表 -->
      <div class="table-wrap"  v-if="ledList.length>0">
        <el-table
          size="mini"
          border
          :max-height="(list.length>0 && ledList.length>0)?200:400"
          v-loading="allLoading"
          style="width: 100%"
          :data="ledList"
          @row-click="e=>rowClick(e,2)"
          :row-class-name="e=>rowClassName(e,2)"
        >
          <el-table-column
            align="center"
            prop="ledCustomerAccountNo"
            width="100"
            label="Select">
            <template slot-scope="scope">
              <span class="iconfont icon-check icon-active" v-show="ledTableSelect==scope.row.ledCustomerAccountNo">&#xe730;</span>
              <span class="iconfont icon-check" v-show="ledTableSelect!=scope.row.ledCustomerAccountNo">&#xe72f;</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="ledCustomerAccountName"
            label="SO Customer Name">
          </el-table-column>
          <el-table-column
            align="center"
            prop="ledCustomerAccountNo"
            label="SO Customer Account No.">
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer" v-loading="loadingBtn">
        <el-button type="primary" class="btn" @click="handleConfirm">{{L.confirmSelect}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {api_customer_list,api_customer_change} from '@/api/api'
import {postAction} from '@/api/tool'


export default {
  name:'CustomerList',
  data() {
    return {
      loadingBtn:false,
      show:false,
      tableSelect:'',
      ledTableSelect:'',
      list:[],
      ledList:[]
    }
  },
  methods:{
    rowClassName(e,flag){
      if(flag==1){
        if(this.tableSelect==e.row.customerAccountNo){
          return 'selected-row';
        }
      }
      if(flag==2){
        if(this.ledTableSelect==e.row.ledCustomerAccountNo){
          return 'selected-row';
        }
      }
      return 'common-table-row-class'
    },
    rowClick(row,flag){
      if(flag==1){
        if(this.tableSelect==row.customerAccountNo){
          this.tableSelect=''
        }else{
          this.tableSelect=row.customerAccountNo
        }
      }
      if(flag==2){
        if(this.ledTableSelect==row.ledCustomerAccountNo){
          this.ledTableSelect=''
        }else{
          this.ledTableSelect=row.ledCustomerAccountNo
        }
      }
    },
    /* 获取客户列表 */
    getCustomerList(){
      this.allLoading=true
      postAction(api_customer_list).then(res=>{
        this.allLoading=false
        this.list=res.dsCustomerList
        this.ledList=res.ledCustomerList
        this.tableSelect=this.$store.state.userInfo.customerAccountNo
        this.ledTableSelect=this.$store.state.userInfo.ledCustomerAccountNo
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 确认选择 */
    handleConfirm(){
      if(!this.tableSelect && !this.ledTableSelect){
        this.$message({
          message: 'Please select at least one account',
          type: 'warning'
        })
        return
      }
      let params = {
        dsCustomerAccountNo:this.tableSelect,
        ledCustomerAccountNo:this.ledTableSelect
      }
      this.loadingBtn=true
      postAction(api_customer_change,params).then(()=>{
        this.loadingBtn=false
        this.goCatalogue()
        this.$router.go(0)
      }).catch(()=>{
        this.loadingBtn=false
      })
    },
    open(){
      this.show=true
      this.getCustomerList()
    },
    close(){
      this.show=false
    }
  }
}
</script>

<style lang="less">
/* 这个是表格部分的css，所以要单独给个style的标签，放在scoped里面不生效的 */
.common-table-row-class{
  &:hover{
    cursor: pointer;
  }
}
.selected-row{
  background-color: #fff0e6 !important;
}
</style>

<style lang="less" scoped>
@import url('../../public/css/public.less');
.quick-wrap{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .quick-order{
    // background-color: red;
    border-radius: 2px;
    position: absolute;
    &:extend(.main-box-shadow);
    width:720px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding:30px 40px;
    box-sizing: border-box;
    text-align: left;
    .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 24px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 24px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
    }
    .title{
      font-size: 16px;
      font-weight: 600;
    }
    .table-wrap{
      margin-top: 20px;
      .icon-check{
        font-size: 19px;
        color:#ccc;
        &:hover{
          cursor: pointer;
        }
      }
      .icon-active{
        color:@mainColor;
      }
    }
    .footer{
      width:100%;
      margin-top:30px;
      .btn{
        white-space:pre-wrap;
        overflow: auto;
        width:100%;
      }
    }
  }
}

</style>