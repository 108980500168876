<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap good-list">
      <!-- 左边产品分类 -->
      <div class="left">
        <div v-show="familyCatList.length>0">
          <!-- 产品目录 -->
          <div class="title">{{L.productCata}}</div>
          <div class="content" 
            v-show="familyCatList.length>0"
            v-for="item in familyCatList" 
            :key="item.categoryPimid" 
            @click="chooseFamilyCat(item)"
          >
            <span class="iconfont icon-check icon-active" v-show="item.show">&#xe730;</span>
            <span class="iconfont icon-check" v-show="!item.show">&#xe72f;</span>
            <span class="text">{{item.categoryName}}</span>
          </div>
          <div v-show="familyCatList.length==0" class="no-catalogue">
            <span class="iconfont icon-sad">&#xe65d;</span>
            <!-- 暂无数据 -->
            <span>{{L.noData}}</span>
          </div>
        </div>
      </div>
      <!-- 右边产品列表 -->
      <div class="right">
        <!-- 搜索结果 -->
        <div class="result-text" v-show="show.searchResultText">
          <span class="in-box">{{familyList.length}} {{L.matchResult}} &lt;&lt; {{$route.query.searchQuery}} &gt;&gt;</span>
        </div>
        <el-row class="row" :gutter="24" type="flex" justify="right" v-show="familyList.length>0">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" class="square-col" v-for="item in familyList" :key="item.familyPimid">
            <div class="square">
              <div class="pic" v-show="item.familyImage">
                <img :src="item.familyImage"/>
              </div>
              <div class="download-family" v-show="item.dataSheetLink">
                <span class="iconfont icon-file">&#xe60a;</span>
                <!-- 系列数据表 -->
                <span @click="downloadFamilySheet(item.dataSheetLink,1)" class="text">{{L.familyDatasheet}}</span>
                <!-- <a :href="item.dataSheetLink" class="text" target="_blank">{{L.familyDatasheet}}</a> -->
                <span class="iconfont icon-download" @click="downloadFamilySheet(item.dataSheetLink,0)">&#xe61c;</span>
              </div>
              <div class="title" v-html="item.headlineLean"></div>
              <div class="content" v-html="item.familyNameLongGeneric"></div>
              <!-- 类型 -->
              <div class="variants">
                <a class="variants-inner" :href="detailUrl(2,item.familyPimid)">
                  <span class="text">{{L.variants}}:  {{item.variants}}</span>
                  <span class="iconfont icon-arrow">&#xe687;</span>
                </a>
              </div>
              <div v-show="$cookies.get('token')">
                <!-- 选择框 -->
                <div class="select" @mouseleave="mouseleave(item)">
                  <div class="choose" @click="clickChooseSelect(item)">
                    <div class="text" v-show="!item.chooseEanObj.eanPimid">
                      <!-- 请选择类型 -->
                      <div class="please">{{L.pleaseChooseVariant}}</div>
                    </div>
                    <div class="text" v-show="item.chooseEanObj.eanPimid">
                      <div class="text1" >
                        <span v-html="item.chooseEanObj.eanName"></span>
                        <span class="phase-out">{{item.chooseEanObj.phaseOut=='Phase-out'?'(PHASE OUT)':''}}</span>
                      </div>
                      <div class="text2">EAN: {{item.chooseEanObj.eanPimid}}</div>
                    </div>
                    <div class="arrow">
                      <span class="iconfont icon_arrow" :class="{'active-arrow':item.showChoose}">&#xe627;</span>
                    </div>
                  </div>
                  <!-- 弹框 -->
                  <div class="choose-wrap" v-show="item.showChoose">
                    <div class="choose" @click="chooseEan(item,item2)" v-for="item2 in item.eanList" :key="item2.eanPimid">
                      <div class="line1">
                        <span v-html="item2.eanName"></span>
                        <span class="phase-out">{{item2.phaseOut=='Phase-out'?'(PHASE OUT)':''}}</span>
                      </div>
                      <div class="line2">EAN:{{item2.eanPimid}}</div>
                    </div>
                  </div>
                </div>
                <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('ATC')">
                  <div class="num-price">
                    <!-- 数量 -->
                    <div class="num">
                      <AddNumber 
                        :disabled="!item.chooseEanObj.eanPimid" 
                        v-model="item.inpVal" 
                        :step="item.chooseEanObj.countAscii" 
                        @blur="e=>blurCount(e,item)" 
                        @click="e=>blurCount(e,item)"
                      />
                    </div>
                    <!-- 价格 -->
                    <div class="price">
                      <!-- 没选EAN -->
                      <!-- 选择EAN 后将显示价格。 -->
                      <div class="price-request" v-show="!item.chooseEanObj.eanPimid">{{L.showPrcieAfterSelect}}</div>
                      <div v-show="item.chooseEanObj.eanPimid">
                        <!-- 价格图标 -->
                        <div class="price-icon"  v-show="!isRequestPrice">
                          <el-tooltip
                            effect="dark"
                            placement="top"
                          >
                            <div slot="content">Check price & stock</div>
                            <div
                              :class="{'no-allow':$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('NOC')}"
                              class="icon-wrap" 
                              @click="handleGetPrice" 
                              v-if="$store.state.userInfo.accountRules && ($store.state.userInfo.accountRules.includes('SP') || $store.state.userInfo.accountRules.includes('SAC'))"
                            >
                              <span
                                v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')" 
                                class="iconfont icon1"
                                :class="{'icon1-border-right':$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP') && $store.state.userInfo.accountRules.includes('SAC')}"
                              >&#xe64a;</span>
                              <span 
                                v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SAC')" 
                                class="iconfont icon2"
                              >&#xe63e;</span>
                            </div>
                          </el-tooltip>
                        </div>
                        <div v-show="isRequestPrice && ($store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP'))">
                          <!-- 没价格或者价格为0,都展示Price on request(unRequest的布尔值是true，所以不冲突) -->
                          <div v-show="!item.chooseEanObj.price">
                            <!-- 价格需要申请 -->
                            <span class="price-request">{{L.priceRequest}}</span>
                          </div>
                          <!-- 既不是false也不是unRequest那就肯定是有价格的 -->
                          <div v-show="item.chooseEanObj.price && item.chooseEanObj.price!=='unRequest'">
                            <div class="price-detail-wrap">
                              <el-tooltip
                                v-if="item.chooseEanObj.priceMsg"
                                effect="dark"
                                placement="top"
                              >
                              <div slot="content">{{item.chooseEanObj.priceMsg}}</div>
                                <span class="iconfont icon-info">&#xed50;</span>
                              </el-tooltip>
                              <span class="price-detail">{{formatMoney(item.totalPrice,item.customerCurrency)}}</span>
                            </div>
                            <div class="per-price">({{formatMoney(item.chooseEanObj.price,item.chooseEanObj.currency)}} {{L.per}} {{item.chooseEanObj.packType}})</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 库存信息 -->
                  <div class="available-stock">
                    <!-- 可用库存数 -->
                    <div class="available-flex" v-if="isRequestPrice && item.chooseEanObj.eanPimid">
                      <span class="text">{{L.availableQuantity}}: 
                        <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SAC')">{{item.chooseEanObj.availableQty}}</template>
                      </span>
                      <el-tooltip 
                        class="item" 
                        effect="dark"
                        placement="top"
                      >
                      <!-- <div slot="content">{{L.quantityRemark}}</div> -->
                      <div slot="content">{{item.chooseEanObj.stockDescription}}</div>
                        <span class="iconfont icon-info">&#xed50;</span>
                      </el-tooltip>
                    </div>
                    <div class="wishlist-flex" v-show="item.chooseEanObj.eanPimid">
                      <!-- <span @click="showWishlist(item)" class="iconfont icon-wishlist">&#xe8b9;</span> -->
                      <el-tooltip 
                        class="item" 
                        effect="dark"
                        placement="top"
                      >
                      <div slot="content">{{L.addToWishlist}}</div>
                        <span @click="showWishlist(item)" class="iconfont icon-wishlist">&#xe60e;</span>
                      </el-tooltip>
                      <div class="wishlist-wrap" v-show="item.showWishlistIcon">
                        <WishlistSelect ref="WishlistSelect" @closeWishlistDialogue="closeWishlistDialogue(item)"/>
                      </div>
                    </div>
                  </div>
                  <div class="add-cart" @click="addToCart(item)" :class="{'no-color':!item.chooseEanObj.eanPimid}">
                    <span class="iconfont icon-cart">&#xe62c;</span>
                    <span class="text">{{L.addCart}}</span>
                  </div>
                </template>
              </div>
            </div>
          </el-col>
        </el-row>
        <NoContent v-show="familyList.length==0"/>
      </div>
    </div>
    
  </div>
</template>

<script>
import AddNumber from '@/components/AddNumber'
import NoContent from '@/components/NoContent'
import WishlistSelect from '@/components/WishlistSelect'
import {
  api_add_to_cart,
  api_family_list,
  api_family_list_price,
  api_family_list_by_search,
  api_family_list_by_search_price,
  api_family_list_by_product,
  api_family_list_by_product_price,
  api_wishlist_list
} from '@/api/api'
import {postAction,getAction,downFileGet} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'Catalogue',
  components:{
    AddNumber,
    NoContent,
    WishlistSelect
  },
  data(){
    return {
      show:{
        searchResultText:false //搜索结果的文本提示
      },
      familyCatList:[], //左边分类的列表
      allFamilyList:[],//右边的family列表(全部的)
      familyList:[], //右边的family列表(显示在页面上的)
      beforePriceFamilyList:[], //在请求价格之前，记录一下当前的右边呈现的列表
      isRequestPrice:false, //是否请求过价格的接口
      wishlistList:[] //收藏夹列表
    }
  },
  watch:{
    /* 
      这个地方监听的原因：有可能通过最上面的一条Browse Catalogue又进到了这个页面。
      那么对这个页面来说只是路由的参数发生了变化，而路由的地址并没有发生变化。
      那么这时候是不会触发mounted钩子的。
      所以只能监听路由，重新获取一下family列表的数据 
    */
    $route(newVal){
      //注意这个地方修改代码要和mounted代码同步进行修改
      let queryArr = Object.keys(newVal.query)
      if(queryArr.includes('categoryPimid')){
        this.getFamilyListNoPrice()
      }else if(queryArr.includes('searchQuery')){
        this.getFamilyListBySearchNoPrice()
      }else if(queryArr.includes('familyPimid')){
        this.getFamilyListByProduct()
      }
    }
  },
  methods:{
    /* 获取family列表（不带价格） */
    getFamilyListNoPrice(){
      this.show.searchResultText=false
      this.isRequestPrice=false
      let params={
        categoryPimid:this.$route.query.categoryPimid
      }
      this.allLoading=true
      getAction(api_family_list,params).then(res=>{
        this.familyCatList=res.productCategoryList || []
        this.familyCatList.forEach((val)=>{
          val.show=true //后端的数据返回后要自己加字段
        })
        this.allFamilyList=res.familyList
        this.allFamilyList.forEach((val)=>{
          val.showChoose=false //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改,还有选择其中一个ean的方法也要一起改】
          val.chooseEanObj={} //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.inpVal=0 //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
        })
        this.getFilterFamilyList()
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 获取价格列表（含价格） */
    getPriceList(){
      this.show.searchResultText=false
      this.beforePriceFamilyList=this.familyList
      this.isRequestPrice=false
      let params={
        categoryPimid:this.$route.query.categoryPimid
      }
      this.allLoading=true
      getAction(api_family_list_price,params).then(res=>{
        this.isRequestPrice=true
        this.allFamilyList=res.familyList
        this.allFamilyList.forEach((val)=>{
          val.showChoose=false //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.chooseEanObj={} //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.inpVal=0 //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
        })
        this.getFilterFamilyList() //根据左边过滤右边的数据
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 根据搜索结果familyId,请求拿到列表（不带价格） */
    getFamilyListBySearchNoPrice(){
      this.show.searchResultText=false
      this.isRequestPrice=false
      let params = {
        familyIdList:this.$cookies.get('familyIdList').split(',')
      }
      this.allLoading=true
      postAction(api_family_list_by_search,params).then(res=>{
        this.show.searchResultText=true
        this.familyCatList=res.productCategoryList || []
        this.familyCatList.forEach((val)=>{
          val.show=true //后端的数据返回后要自己加字段
        })
        this.allFamilyList=res.familyList
        this.allFamilyList.forEach((val)=>{
          val.showChoose=false //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改,还有选择其中一个ean的方法也要一起改】
          val.chooseEanObj={} //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.inpVal=0 //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
        })
        this.getFilterFamilyList()
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 根据搜索结果familyId,请求拿到列表(含价格) */
    getFamilyListPriceBySearchPrice(){
      this.show.searchResultText=false
      this.beforePriceFamilyList=this.familyList
      this.isRequestPrice=false
      let params={
        familyIdList:this.$cookies.get('familyIdList')
      }
      this.allLoading=true
      postAction(api_family_list_by_search_price,params).then(res=>{
        this.show.searchResultText=true
        this.isRequestPrice=true
        this.allFamilyList=res.familyList
        this.allFamilyList.forEach((val)=>{
          val.showChoose=false //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.chooseEanObj={} //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.inpVal=0 //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
        })
        this.getFilterFamilyList() //根据左边过滤右边的数据
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 从product页面过来的（不带价格） */
    getFamilyListByProduct(){
      this.show.searchResultText=false
      this.isRequestPrice=false
      let params={
        familyPimid:this.$route.query.familyPimid
      }
      this.allLoading=true
      getAction(api_family_list_by_product,params).then(res=>{
        this.familyCatList=res.productCategoryList || []
        this.familyCatList.forEach((val)=>{
          val.show=true //后端的数据返回后要自己加字段
        })
        this.allFamilyList=res.familyList
        this.allFamilyList.forEach((val)=>{
          val.showChoose=false //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改,还有选择其中一个ean的方法也要一起改】
          val.chooseEanObj={} //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.inpVal=0 //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
        })
        this.getFilterFamilyList()
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 从product页面过来的（带价格） */
    getFamilyListByProductPrice(){
      this.show.searchResultText=false
      this.beforePriceFamilyList=this.familyList
      this.isRequestPrice=false
      let params={
        familyPimid:this.$route.query.familyPimid
      }
      this.allLoading=true
      getAction(api_family_list_by_product_price,params).then(res=>{
        this.isRequestPrice=true
        this.allFamilyList=res.familyList
        this.allFamilyList.forEach((val)=>{
          val.showChoose=false //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.chooseEanObj={} //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
          val.inpVal=0 //后端的数据返回后要自己加字段【注意！！这里和其他5个获取列表的方法要一起改，还有选择其中一个ean的方法也要一起改】
        })
        this.getFilterFamilyList() //根据左边过滤右边的数据
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 选择其中一个ean */
    chooseEan(item,item2){
      this.familyList.forEach((val,i)=>{
        if(item.familyPimid===val.familyPimid){
          val.showChoose=false //【注意！！这里和上面4个获取列表的方法要一起改的】
          val.chooseEanObj=item2 //【注意！！这里和上面4个获取列表的方法要一起改的】
          // val.inpVal=item2.countAscii //【注意！！这里和上面4个获取列表的方法要一起改的】
          val.inpVal=item2.moq //【注意！！这里和上面4个获取列表的方法要一起改的】
          val.totalPrice=val.inpVal*val.chooseEanObj.listPriceConversion //价格(跟inpVal挂在了同一级，并不是挂在chooseEanObj这里面的)，这个是选择之后才有的，所以跟上面4个获取列表的方法没关系
          this.$set(this.familyList,i,val) //用这个才能深层嵌套数据刷新页面，否则页面渲染不会更新的
        }
      })
    },
    /* 选择左边的其中某一个，决定勾选或者不勾选，并且右边的数据要更新 */
    chooseFamilyCat(item){
      this.familyCatList.forEach((val,i)=>{
        if(val.categoryPimid===item.categoryPimid){
          val.show=!val.show
          this.$set(this.familyCatList,i,val) //用这个才能深层嵌套数据刷新页面，否则页面渲染不会更新的
        }
      })
      this.getFilterFamilyList()
    },
    /* 点击获取价格 */
    handleGetPrice(){
      if(this.$store.state.userInfo.accountRules && this.$store.state.userInfo.accountRules.includes('NOC')){
        return
      }
      let queryArr = Object.keys(this.$route.query)
      if(queryArr.includes('categoryPimid')){
        this.getPriceList()
      }else if(queryArr.includes('searchQuery')){
        this.getFamilyListPriceBySearchPrice()
      }else if(queryArr.includes('familyPimid')){
        this.getFamilyListByProductPrice()
      }
    },
    /* 根据左边的选择与否，抓取右边的数据 */
    getFilterFamilyList(){
      this.familyList=[]
      let showTrueCatArr = [] //左边选择勾上的id的数组
      let finalShowList = [] //最终展示出来的列表
      this.familyCatList.forEach((val)=>{
        if(val.show){
          showTrueCatArr.push(val.categoryPimid)
        }
      })
      //如果左边分类是有数据的，那么右边的要根据左边的选择进行过滤。如果左边没数据，那么右边就直接展示所有的数据，没有过滤
      if(this.familyCatList.length>0){
        this.allFamilyList.forEach((val)=>{
          if(showTrueCatArr.includes(val.parentPimid)){
            finalShowList.push(val)
          }
        })
      }else{
        finalShowList=this.allFamilyList
      }
      this.beforePriceFamilyList.forEach((val)=>{
        //再循环带价格的list，带价格的里面要循环eanList这个字段,这个里面的eanPimid和老的里面的chooseEanObj.eanPimid一样的话，就把familyList里面的chooseEanObj给赋值一下
        finalShowList.forEach((val2)=>{
          val2.eanList.forEach((val3)=>{
            if(val.chooseEanObj.eanPimid===val3.eanPimid){
              val2.chooseEanObj=val3
              val2.inpVal=val.inpVal //这里取老的list里的数量，因为可能被修改过了【注意！！这里和chooseEan方法要一起改的】
              val2.totalPrice=val2.inpVal*val3.listPriceConversion //【注意！！这里和chooseEan方法要一起改的】
            }
          })
        })
      })
      this.familyList=finalShowList
      //先循环老的list，注意老的list需要及时清空的，因为这个过滤的方法在很多地方都被调用过，不清空会造成老数据的错乱
      this.beforePriceFamilyList=[]
    },
    /* 点击选择框 */
    clickChooseSelect(item){
      //把wishlist的对话框关闭掉
      this.familyList.forEach((val)=>{
        val.showWishlistIcon=false
      })
      this.familyList.forEach((val,i)=>{
        if(val.familyPimid===item.familyPimid){
          val.showChoose=!val.showChoose
          this.$set(this.familyList,i,val) //用这个才能深层嵌套数据刷新页面，否则页面渲染不会更新的
        }
      })
    },
    /* 鼠标移开那个选择ean的框子 */
    mouseleave(item){
       this.familyList.forEach((val,i)=>{
        if(item.familyPimid===val.familyPimid){
          val.showChoose=false
          this.$set(this.familyList,i,val) //用这个才能深层嵌套数据刷新页面，否则页面渲染不会更新的
        }
      })
    },
    /* 下载family sheet */
    downloadFamilySheet(src,flag){
      let url=src.includes('inventronicsglobal')?src.split(Vue.config.DAM_URL)[1]:src.split(Vue.config.OSR_URL)[1]
      url=this.decodeHtml('/'+url)
      this.allLoading=true
      downFileGet(url).then(res=>{
        this.allLoading=false
        this.downloadFile(res,flag)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 添加到购物车 */
    addToCart(item){
      //没有选择过的话，就禁止往下走
      if(!item.chooseEanObj.eanPimid){
        this.$message.warning(this.L.pleaseChooseVariant)
        return
      }
      if(item.inpVal==0){
        this.$message.warning(this.L.quantityCannot0)
        return
      }
      let params = {
        skuId:item.chooseEanObj.eanPimid,
        skuCount:item.inpVal
      }
      this.allLoading=true
      postAction(api_add_to_cart,params).then(()=>{
        this.allLoading=false
        this.$message.success(this.L.operateSuccess)

        this.getCartCount()
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 修改数量 */
    blurCount(e,item){
      let num = e
      let countAscii = item.chooseEanObj.countAscii
      let moq = item.chooseEanObj.moq

      num = num%countAscii==0?num:(Math.floor(num/countAscii+1))*countAscii
      // num = num>=countAscii?num:countAscii
      num = num>=moq?num:moq

      this.familyList.forEach((val,i)=>{
        if(val.familyPimid===item.familyPimid){
          val.inpVal=num
          val.totalPrice=val.inpVal*item.chooseEanObj.listPriceConversion
          this.$set(this.familyList,i,val) //用这个才能深层嵌套数据刷新页面，否则页面渲染不会更新的
        }
      })
    },
    /* 选择wishlist */
    showWishlist(item){
      let arr = [item.chooseEanObj]
      this.familyList.forEach((val,i)=>{
        val.showWishlistIcon=false
        if(val.familyPimid==item.familyPimid){
          val.showWishlistIcon=true
          this.$set(this.familyList,i,val) //触发渲染
          this.$refs.WishlistSelect[i].setNowProductItem(arr,this.wishlistList) //打开whishlist对话框
        }
      })
    },
    /* 关闭wishlist对话框 */
    closeWishlistDialogue(item){
      this.familyList.forEach((val,i)=>{
        if(val.familyPimid==item.familyPimid){
          val.showWishlistIcon=false
          this.$set(this.familyList,i,val) //触发渲染
        }
      })
    },
    /* 获取wishlist列表的数据 */
    getWishlistList(){
      let params = {
        isShare:1
      }
      getAction(api_wishlist_list,params).then(res=>{
        this.wishlistList=res
      })
    }
  },
  mounted(){
    //进来的时候回到顶部（因为从home页进来的是，可能是从home的最下面的产品数据里进来的，这时候页面就被往下滑了很多）
    this.goTop()
    //存在token的话，获取一下whishlist列表的数据
    if(this.$cookies.get('token')){
      this.getWishlistList()
    }
    //这块跟上面的watch $route的代码要一起改的
    let queryArr = Object.keys(this.$route.query)
    if(queryArr.includes('categoryPimid')){
      this.getFamilyListNoPrice()
    }else if(queryArr.includes('searchQuery')){
      this.getFamilyListBySearchNoPrice()
    }else if(queryArr.includes('familyPimid')){
      this.getFamilyListByProduct()
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>